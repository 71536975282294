.reg-container {
  z-index: 3;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
}
.reg-container h1 {
  width: 70%;
  margin-bottom: 50px;
}
.logo-img {
  /* width: 300px; */
  height: 120px;
  margin: 30px 0 50px 0;
}

.btn-bg {
  background-color: #69a32c;
}

.btn-wrapper {
  margin-bottom: 50px;
}
.btn-success {
  background-color: #69a32c !important;
  border-color: #69a32c !important;
  /* margin-left: 80px; */
  height: 40px;
  border-radius: 0 !important;
  font-weight: bold !important;
  font-size: 21px !important;
  padding: 0px 12px !important;
  width: 135px;
}

@media screen and (max-width: 800px) {
  .reg-container {
    width: 85%;
    margin: 0 auto;
  
  }
  .reg-container h1 {
    width: 85%;
    /* margin-bottom: px; */
  }
}



.reg-btn{
  height: 71px;
  font-size: 25px !important;
  font-weight: bold !important;
  margin: 0 30px;
  line-height: 0 !important;
  width: 295px;
}

@media screen and (max-width: 550px) {
  .reg-btn{
    font-size: 16px !important;
  }
}

@media screen and (max-width: 400px) { /* mobile screen*/
  .reg-btn{
    margin: 0 10px;
  }
  
}
