
input[type=radio] {
  border: 2px solid #fff;
  background-color: #fff;
  padding: 0.4em;
  -webkit-appearance: none;
}

input[type=radio]:checked {
 background-color: #598d25;
 padding: 0.4em;
 border: 2px solid #fff;
}

input[type=radio]:focus {
  outline-color: transparent;
}



.checkbox-button-gray-bg {
  position: relative;
  display: inline-block;
  width: 17px;
  height: 17px;
  vertical-align: middle;
  background-color: inherit;
  color: gray;
  border: 2px solid #fff;
  background-color: #fff;
}

.checkbox-button-gray-bg:after{
  content: "";
  display: block;
  position: absolute;
  top: -1px;
  left: -1px;
  width: 15px;
  height: 15px;
  background-color: gray;
  border: 1px solid #fff;
  cursor: not-allowed;
}


