.checkbox-button {
  cursor: pointer;
}

input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input {
  font-size: 1rem;
  line-height: 1.5;
  padding: 11px 23px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
  outline: 0;
  background-color: transparent;
}

.checkbox-button__input {
  opacity: 0;
  position: absolute;
}

.checkbox-button__control {
  position: relative;
  display: inline-block;
  width: 21px;
  height: 21px;
  vertical-align: middle;
  background-color: inherit;
  color: #017b5f;
  border: 2px solid #fff;
  background-color: #fff;
}

.checkbox-button__input:checked + .checkbox-button__control {
  border-color: #fff;
}

.checkbox-button__control{
  transform: scale(0.75);
}

.checkbox-button-green-bg {
  position: relative;
  display: inline-block;
  width: 17px;
  height: 17px;
  vertical-align: middle;
  background-color: inherit;
  color: #017b5f;
  border: 2px solid #fff;
  background-color: #fff;
}

.checkbox-button-green-bg:after{
  content: "";
  display: block;
  position: absolute;
  top: -1px;
  left: -1px;
  width: 15px;
  height: 15px;
  background-color: #598d25;
  border: 1px solid #fff;
}



input[type=radio] {
  border: 2px solid #fff;
  background-color: #fff;
  padding: 0.4em;
  -webkit-appearance: none;
}

input[type=radio]:checked {
 background-color: #598d25;
 padding: 0.4em;
 border: 2px solid #fff;
}

input[type=radio]:focus {
  outline-color: transparent;
}

