.next {
  width: 88px;
  height: 40x;
  color: #fff;
  background-color: #59972b;
}

.fs-21 {
  font-size: 21px;
}
.next-icon {
  width: 40px;
  height: 40px;
  color: #000;
  background-color: #8edd65;
  line-height: 33px;
  text-align: center;
  margin: 0 10px;
  cursor: pointer;
}

.wrapper {
  width: 80%;
  margin: 0 auto;
  margin-top: 30px;
  direction: rtl;
}

.wrapper-two {
  width: 80%;
  margin: 0 auto;
  margin-top: 35px;
  direction: rtl;
}
.wrapper-two-ar {
  width: 80%;
  margin: 0 auto;
  margin-top: 60px;
  direction: rtl;
}
.wrapper-step-3-ar {
  width: 80%;
  margin: 0 auto;
  margin-top: 70px;
  direction: rtl;
}

.wrapper-workshop-ar {
  width: 80%;
  margin: 0 auto;
  margin-top: 260px;
  direction: rtl;
}

.wrapper-workshop {
  width: 80%;
  margin: 0 auto;
  margin-top: 270px;
  direction: rtl;
}

@media screen and (max-width: 800px) {
  .content-text-workshop {
    width: 73% !important;
    margin: 0 auto;
  }

  .content-text-workshop-btn {
    width: 100% !important;
    margin: 0 auto;
  }
  .mt-tab {
    margin-top: 20px;
  }
  .wrapper-step-3-ar {
    width: 80%;
    margin: 0 auto;
    margin-top: 65px;
    direction: rtl;
  }

  .wrapper-workshop-ar {
    width: 80%;
    margin: 0 auto;
    margin-top: 260px;
    direction: rtl;
  }

  .wrapper-workshop {
    width: 80%;
    margin: 0 auto;
    margin-top: 270px;
    direction: rtl;
  }

  .mt-20 {
    margin-top: 20px;
  }
  .D-en {
    height: 116px !important;
  }
  .wrapper-ar {
    width: 80%;
    margin: 0 auto;
    margin-top: 220px !important;
    direction: rtl;
  }
  .wrapper {
    margin-top: 120px;
  }
  .wrapper-step-3 {
    margin-top: 70px !important;
  }
  .wrapper-two {
    margin-top: 20px;
  }
  .wrapper-two-ar {
    margin-top: 105px;
  }
}

.wrapper-one {
  width: 80%;
  margin: 0 auto;
  margin-top: 65px;
  direction: rtl;
}

.wrapper-ar {
  width: 80%;
  margin: 0 auto;
  margin-top: 125px;
  direction: rtl;
}

.wrapper-step-3 {
  width: 80%;
  margin: 0 auto;
  margin-top: 70px;
  direction: rtl;
}
.content-text {
  width: 68%;
  margin: 0 auto;
}

.confirm-text {
  font-size: calc(10px + 3vmin);
  text-align: center;
  font-weight: bold;
  margin-top: 90px;
  width: 75%;
}
.error {
  color: red;
  font-weight: bold;
  margin-bottom: 3px;
  margin-top: -18px;
}
.input-error {
  color: red;
  margin-bottom: 3px;
  margin-top: -11px;
  font-size: 14px !important;
}

@media screen and (max-width: 400px) {
  /* mobile screen*/
  .logo {
    height: 59px;
  }
  .main-text {
    margin: 10px 0;
  }
  .vid-mobile {
    height: 200px;
    width: 200px;
  }
  .reg-btn {
    height: 71px;
    font-size: 20px !important;
    font-weight: bold !important;
    margin: 0 5px;
    line-height: 0 !important;
    width: 170px;
  }
  .contant-container {
    background-size: 339px 918px;
  }
  .company-text,
  .fs-5,
  .form-check-label,
  h5 {
    font-size: 13px !important;
  }
  .ms-3,
  .mb-3,
  .mb-4 {
    margin: 0 !important;
  }
  .company-services {
    margin-top: 10px;
  }
  .next-icon {
    display: none;
  }
  h5 {
    margin: 0;
  }
  .wrapper {
    margin-top: 10px !important;
  }
  .padding-step-two {
    padding-top: 50px !important;
  }
  .header-container {
    margin: 20px 0 0 0 !important;
  }
  .contant-container {
    margin-bottom: 0 !important;
  }
  .q1-height {
    height: 215px !important;
  }
  .q3-height {
    height: 30px !important;
  }
  .q4-height {
    height: 230px !important;
  }
  .wrapper {
    margin-top: 35px !important;
  }
  .wrapper-ar {
    margin-top: 130px !important;
  }

  .form-control-q {
    height: 35px;
  }
  .mb-mobile {
    margin-bottom: 15px;
  }
  .wrapper-step-3 {
    width: 90%;
    margin: 0 auto;
    margin-top: 315px !important;
  }
  .wrapper-step-3-ar {
    width: 90%;
    margin: 0 auto;
    margin-top: 260px !important;
  }
  .mt-8em {
    margin-top: 7em !important;
  }
}

@media screen and (min-width: 1500px) {
  .wrapper-two {
    width: 80%;
    margin: 0 auto;
    margin-top: 42px;
    direction: rtl;
  }
}

.D {
  height: 80px;
}

.row > * {
  padding-left: 0 !important;
}
.ms-3 {
  margin: 0;
}

.content-text-workshop {
  width: 47%;
  margin: 0 auto;
}

.content-text-workshop-btn {
  width: 100%;
  margin: 0 auto;
}

.error-msg {
  display: flex;
  justify-content: end;
  color: red;
}
.not-found{
  padding-top: 200px;
  height: 600px;
}