

#outer{
    margin: auto;
    margin-top: 15%;
    background:#73B42F;
    height: 100px;
    width: 100px;
    border-radius: 15px;
  }
  #middle{
    height: 60px;
    width: 60px;
    margin:auto;
    position: relative;
    top:20px;
    border-radius: 50%;
    background-image: linear-gradient(150deg, transparent 50%, #59972B 50%),linear-gradient(90deg, #59972B 50%,#fff 50%);
     -webkit-animation: rotation 1200ms infinite linear;
    transform-origin: 50% 50%;
    animation-timing-function: ease;
  }
  #inner{
    background: #73B42F;
    height: 48px;
    width: 48px;
    margin:auto;
    position: relative;
    top:6px;
    border-radius: 50%;
  }
  @-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(270deg);
  }
  to{
    -webkit-transform: rotate(630deg);
    }
  }