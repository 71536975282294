.ar-container {
  z-index: 3;
  color: #fff;
  width: 90%;
  margin: 0 auto;
}

.header-container {
  display: flex;
  justify-content: space-between;
  margin: 45px 0;
}
.logo {
  /* width: 250px; */
  height: 80px;
  cursor: pointer;
}
.padding-control {
  padding-top: 90px;
}
.padding-step-one {
  padding-top: 80px;
}
.padding-step-two {
  padding-top: 70px;
}
.contant-container {
  background-image: url(../../Assets/Group\ 76.png);

  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 992px;
  background-size: 650px 960px;
}

.content-text {
  width: 68%;
  margin: 0 auto;
}

@media screen and (min-width: 1500px) {
  .content-text {
    width: 60% !important;
  }
  .wrapper-two {
    width: 80%;
    margin: 0 auto;
    margin-top: 55px;
    direction: rtl;}

}

@media screen and (max-width: 900px) {
  .content-text {
    width: 85% !important;
  }

  .contant-container {
    background-size: 700px 785px;
  }
}

@media screen and (max-width: 800px) {
  .content-text {
    width: 90% !important;
  }

  .contant-container {
    height: 992px;
    background-size: 650px 960px;
  }

  .mt-8em {
    margin-top: 8em !important;
  }
}

@media screen and (max-width: 750px) {
  .contant-container {
    background-size: 600px 785px;
  }
}
.company-text {
  color: #8edd65;
 font-weight: bold;

}

.form-control {
  background-color: #59972b30 !important;
  border: none !important;
  color: #fff !important;
  margin-bottom: 10px;
  opacity: 0.8;
  width: 100% !important;
}
.form-control-q {
  background-color: #59972b !important;
  border: none !important;
  color: #fff !important;
  margin-top: 10px;
  opacity: 0.8;
  width: 100% !important;
}

.register-text {
  cursor: pointer;
  font-weight: bold;
}

.company-services {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}
/* .main-text {
  margin: 2.5em 0 1.5em 0;
} */

.red{color: #8edd65;}

