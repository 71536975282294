.nav-tabs .nav-link.active {
  color: #fff;
  background-color: transparent !important;
  border-color: transparent  transparent green transparent  !important;
}
.nav-tabs .nav-link:hover {
  border-color: transparent transparent green  transparent  !important;
}

.nav-link {
  color: #fff;
  padding: 0 0px;
}

.nav-link:hover {
  color: #fff;
}
.nav-tabs{
  border-bottom: none;
}

.nav-tabs .nav-link{
  border: 3px solid transparent !important;
}
