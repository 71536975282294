.footer-container {
    display: flex;
    justify-content: space-between;
  }
  
  @media screen and (max-width: 550px) {
    .footer-container {
      flex-direction: column;
      align-items: center;
    }
  }
  .footer-img {
    width: 190px;
    height: 30px;
  }
@media screen and (max-width: 500px) {
    /* mobile screen*/
    .footer-container {
      text-align: center;
    }

    .footer-mobile{
      text-align: center;
    }
  }