
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@400;500;700&family=Work+Sans:wght@400;600;700&display=swap');

* {
  font-family: 'Tajawal', sans-serif;
}

.video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

::placeholder {
  color: #fff !important;
}

:checked {
  background-color: green;
}

.fs-15 {
  font-size: 15px;
}

label {
  font-size: 17px;
  font-weight: 600;
}

.form-check {
  padding-left: 0 !important;
}

label,
.fs-16 {
  font-size: 16px;
}

h5 {
  font-size: 21px !important;
}

.w-25{
  width: 25%;
}
.w-18{
  width: 15%;
}
.h-600{
  height: 600px;
}