.bg-color {
  background-color: #59972b30;
  height: 450px;
  overflow-y: scroll;
  scrollbar-color: #6969dd #e0e0e0;
  scrollbar-width: thin;
  font-size: 14px;
  padding: 5px;
}

.bg-color::-webkit-scrollbar {
  width: 7px;
}

.bg-color::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}

.bg-color::-webkit-scrollbar-thumb {
  background: #8EDD65; 
  border-radius: 10px;
}
